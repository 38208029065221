<template>
  <v-card>
    <v-card-title>
      Import Rozliczeń
      <v-spacer></v-spacer>
    </v-card-title>
    <v-form>
        <v-container fluid>
            <v-row>
                <v-col
                cols="12"
                >
                <v-file-input v-model="boltFile" label="Plik Bolt"></v-file-input>
                </v-col> 
                <v-col cols="12">
                  <v-file-input v-model="deligoFile" label="Plik Deligo"></v-file-input>
                </v-col>
                <v-col
                cols="12"
                >
                <v-file-input v-model="uberFile" label="Plik Uber"></v-file-input> 
                </v-col> 
                <v-col cols="12">
                  <v-file-input v-model="UberEatsFile" label="Plik Uber Eats"></v-file-input>
                </v-col>
                <v-col
                cols="12"
                >
                <v-file-input v-model="freeNowFile" label="Plik FreeNow"></v-file-input>
                </v-col>                 
                <v-col cols="12">
                  <v-file-input v-model="freeNowBonusFile" label="Plik FreeNow Bonus"></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="importWeek" label="Tydzień Rozliczeniowy" type="week"></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >                
                <v-btn class="primary" style="width:100%;" @click="importFiles()">Importuj</v-btn>
                </v-col>                                          
            </v-row>
        </v-container>
    </v-form>
  </v-card>   
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
export default {
  name: "Import",
  data: () => ({
    deligoFile:null,
    boltFile:null,
    uberFile: null,
    freeNowFile: null,
    freeNowBonusFile: null,
    importWeek: null,
    UberEatsFile:null,
  }),
  methods:{
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },      
    async importFiles(){       
      if (!this.importWeek) {        
        await Swal.fire("Błąd", 'Brak Wybranego Tygodnia', "error");
        return false
      }                         
      Swal.fire("Uwaga", 'Rozpoczęto Import, nie odświeżaj strony!', "warning").then(async()=>{
        await this.toggleLoading(true);
        try {
          const formData = new FormData()
          try{
            formData.append("BoltFile", this.boltFile, this.boltFile.name);
          }catch(e){
            console.log('Missing Bolt')
          }
          try {
            formData.append("DeligoFile", this.deligoFile, this.deligoFile.name);
          } catch (e) {
            console.log('Missing DeligoFile')
          }          
          try {
            formData.append("UberFile", this.uberFile, this.uberFile.name);
          } catch (e) {
            console.log('Missing Uber')
          }
          try {
            formData.append("UberEatsFile", this.UberEatsFile, this.UberEatsFile.name);
          } catch (e) {
            console.log('Missing UberEatsFile')
          }          
          try {
            formData.append("FreeNowFile", this.freeNowFile, this.freeNowFile.name);
          } catch (e) {
            console.log('Missing FreeNow')
          } 
          try {
            formData.append("FreeNowBonusFile", this.freeNowBonusFile, this.freeNowBonusFile.name);
          } catch (e) {
            console.log('Missing FreeNow Bonus')
          }                                                           
          formData.append('weekStr', this.importWeek)
          await axios({
            method: "post",
            url: process.env.VUE_APP_ROOT_API + "/settlements/upload-file",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "multipart/form-data"
            },
            data: formData
          })
          this.boltFile = null
          this.deligoFile = null
          this.uberFile = null
          this.freeNowFile = null
          this.freeNowBonusFile = null
          this.UberEatsFile = null
          this.weekStr = null
          Swal.fire("Sukces", 'Zakończono Import', "success");
        } catch (e) {
          console.log(e);
          Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
        }
        await this.toggleLoading(false);
      })   
    }
  }  
}
</script>