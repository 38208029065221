<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card class="elevation-12">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>BTP</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field prepend-icon="mdi-account" name="login" v-model="username" label="E-mail"
                                type="text"></v-text-field>
                            <v-text-field id="password" v-model="password" prepend-icon="mdi-lock" name="password"
                                label="Hasło" type="password"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="login()">Zaloguj</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
const axios = require("axios");
import Swal from "sweetalert2";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LeeTtwiAAAAAPYyKHvL5dtytGDSrc3e0gNR8qjp" });

export default {
    name: "Login",
    data: () => ({
        password: "",
        username: "",
    }),
    props: {
        source: String,
    },
    methods: {
        async login() {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('login')
            try {
                if (!this.username.trim().length) {
                    throw ({ response: { statusText: 'Podaj E-mail' } })
                }
                if (!this.password.trim().length) {
                    throw ({ response: { statusText: 'Podaj Hasło' } })
                }                
                let user = await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/auth/login",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                    data: {
                        email: this.username.toLocaleLowerCase(),
                        password: this.password,
                        token
                    },
                });                
                localStorage.setItem("user", true);
                localStorage.setItem("token", user.data.access_token);
                localStorage.setItem("role", user.data.role);
                localStorage.setItem("userid", user.data.id);
                localStorage.setItem("whid", user.data.whid);
                window.location.href = "/";
            } catch (e) {
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
                localStorage.setItem("user", false);
                localStorage.setItem("token", null);
                localStorage.setItem("role", null);
                localStorage.setItem("userid", null);
                localStorage.setItem("whid", null);
            }
        },
    },
};
</script>