<template>
  <v-card>
    <v-card-title>
      Rozliczenia
      <v-spacer></v-spacer>
      <v-text-field label="Tydzień" v-model="weekNo" type="week"
        @change="loadSettlements()"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-select @change="loadSettlements()" v-model="settlementType" :items="settlementTypes" item-text="text" item-value="id" label="Rodzaj Rozliczenia"></v-select>
      <v-spacer></v-spacer>
        <v-select  @change="loadSettlements()" label="Oddział"  v-model="departament" :items="departaments" item-text="departamentName" item-value="id"></v-select>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Szukaj"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="settlements"
      :options.sync="options"
      :server-items-length="totalRecords"
      :loading="loading"
      :search="search"
      class="elevation-1"
    ></v-data-table>    
      <v-footer class="justify-center">
        <v-btn class="primary" @click="createSettlement()">
          Generuj Wypłaty
        </v-btn>
      </v-footer>
  </v-card>   
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
export default {
  name: "Settlements",
  data: () => ({
        search: '',
        options:{
          sortDesc:'true'
        },
        totalRecords:0,
        loading:false,
        departaments:[],
        departament:'',
        headers: [
          {
            text: 'Kierowca',
            align: 'start',          
            value: 'driverName',
          },
          {
            text: 'Rodzaj Rozliczenia',
            align: 'start',
            value: 'settlementType',
            sortable:false,
          },          
          {
            text: 'Łącznie Bolt',
            align: 'start',
            value: 'totalBolt',
          },  
          {
            text: 'Łącznie Uber',
            align: 'start',
            value: 'totalUber',
          },                       
          {
            text: 'Łącznie FreeNow',
            align: 'start',
            value: 'totalFreeNow',
          }, 
          {
            text: 'Łącznie Uber Eats',
            align: 'start',
            value: 'totalUberEats',
          },  
          {
            text: 'Łącznie Deligo',
            align: 'start',
            value: 'totalDeligo',
          },                    
          {
            text: 'Łącznie Premie i Kary',
            align: 'start',
            value: 'bonusBalanceTotal',
          },           
          {
            text: 'Łącznie Zwrot VAT',
            align: 'start',
            value: 'totalInvoiceReturn',
          },
          {
            text: 'Łącznie DKV',
            align: 'start',
            value: 'totalDkvBalance',
          },                                                                                
          { text: 'Koszt Rozliczenia', value: 'cost', sortable: false }, 
          { text: 'Suma do Wypłaty', value: 'total',sortable:false },          
        ],
    settlements: [],
    weekNo: dayjs().format('YYYY') + '-W' + (dayjs().week()-1),
    settlementType: 'week',
    settlementTypes: [{
      id: 'week',
      text: 'Tygodniowe'
    },
    {
      id: 'two_week',
      text: '2 Tygodnie'
    },
    {
      id: 'month',
      text: 'Miesięczne'
    }
    ],    
  }),
  watch: {
    options: {
      async handler() {
        if(!this.departament){
          await this.getDepartamentSelect()
        }        
        await this.loadSettlements()
      },
      deep: true,
    },
    async search() {
      await this.loadSettlements()
    }
  },
  async beforeMount() {
    
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async getDepartamentSelect() {
      await this.toggleLoading(true);
      try {
        let dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/departaments/list-departaments-for-user/${localStorage.getItem('userid')}?itemsPerPage=100&sort=departamentName&direction=${false}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dep.data.data.items.forEach((item,i)=>{
          if(i==0){
            this.departament = item.id
          }
        })
        this.departaments = dep.data.data.items;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },    
    async loadSettlements() {      
      this.loading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        const settlements = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/settlements/list-payouts/${this.weekNo}/${this.settlementType}/${this.departament}?sort=${sortBy.length ? sortBy : 'driverName'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });  
        settlements.data.data.items.forEach((item)=>{
          if (item.coopType == 'connection'){
            item.settlementType = 'Podpięcie'
          }else if(item.coopType == 'mob'){
            item.settlementType = '50/50'
          }else if(item.coopType == 'b2b_no_vat'){
            item.settlementType = 'B2B Bez Vat'
          } else if (item.coopType == 'b2b_vat') {
            item.settlementType = 'B2B Z Vat'
          }
        })              
        this.settlements = settlements.data.data.items
        this.itemsPerPage = settlements.data.data.meta.itemsPerPage
        this.page = settlements.data.data.meta.currentPage
        this.totalRecords = settlements.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loading = false
    },      
    async createSettlement() {         
      try {
        Swal.fire({
          title: "Utworzyć Rozliczenie?, Może to chwilę potrwać!",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.toggleLoading(true);   
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "/settlements/create",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: {
                weekStr: this.weekNo,
                settlementType: this.settlementType,
                departament: this.departament
              }
            });
            await this.toggleLoading(false);  
            Swal.fire("Sukces", 'Utworzono Rozliczenia !', "success");
            await this.loadSettlements()
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }         
    }    
  },
}
</script>
