<template>
  <v-card>
        <v-dialog :loading="dialogCreateLoading" v-model="dialogCreate" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <!-- <v-card-title>
              <span class="text-h5">{{action}} Kierowcy</span>
            </v-card-title> -->
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogCreate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{action}} Kierowcy</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="Imię" v-model="firstName" required placeholder="Imię"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Nazwisko" v-model="lastName" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                      placeholder="Nazwisko"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Telefon" v-model="phone" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                      placeholder="Telefon"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Hasło" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="password" v-model="password"
                      placeholder="Hasło"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="E-mail" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="email" v-model="email"
                      placeholder="E-mail"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Adres" v-model="street" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                      placeholder="Adres"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Adres 2" v-model="streetExtraInfo" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                      placeholder="Adres 2">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Miasto" v-model="city" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                      placeholder="Miasto">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select label="Oddział" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="departament"
                      :items="departaments" item-text="departamentName" item-value="id"></v-select>
                  </v-col>
                  <v-divider></v-divider>
                  <v-col cols="2">
                    <v-checkbox label="Konto za Granicą?" v-model="abroadBankAccount"></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-checkbox label="Wypłaty Gotówką" v-model="cashPayments"></v-checkbox>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field label="Numer Konta" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="accountNo">
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-select label="Częstotliwość Wypłat" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="paymentSchedule"
                      :items="paymentsSchedules" item-text="text" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-checkbox label="Poniżej 26 Roku Życia?" v-model="isUnder26"></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-checkbox label="Zatrudniony" v-model="isEmployed"></v-checkbox>
                  </v-col>
                  <v-col cols="2">
                    <v-checkbox label="Student" v-model="isStudent"></v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-select label="Rodzaj Współpracy" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="cooperationType"
                      :items="cooperationTypes" item-text="text" item-value="id"></v-select>
                  </v-col>
                  <v-divider></v-divider>
                  <v-col cols="12">
                    <v-text-field label="Numer Licencji" placeholder="Numer Licencji" v-model="licenceNumber"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="ID Bolt" placeholder="ID Bolt" v-model="boltId"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="ID Uber" placeholder="ID Uber" v-model="uberId"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="ID UberEats" placeholder="ID UberEats" v-model="uberEatsId"></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field label="ID FreeNow" placeholder="ID FreeNow" v-model="freeId"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCreate = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="createDriver()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog :retain-focus="false"  v-model="dialogBonuses" persistent max-width="600px">
          <v-card>
        <v-card-title>
          <span class="text-h5">Dodawanie Bonusu</span>
        </v-card-title>
        <v-card-text>
            <v-from>
              <v-row>
              <v-col cols="12">
                <v-text-field label="Kwota" v-model="priceBonus" placeholder="Kwota" :rules="[(v) => !!v || 'Uzupełnij Pole']">
                </v-text-field>
              </v-col>
                  <v-col cols="12">
                    <v-text-field label="Opis" v-model="descriptionBonus" placeholder="Opis"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
              <v-col cols="12">
                <v-select label="Rodzaj" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="balanceType" :items="balanceTypes"
                  item-text="text" item-value="id"></v-select>
              </v-col>
                <v-col cols="12">
                  <v-text-field v-model="bonusWeek" label="Tydzień Rozliczeniowy" type="week"></v-text-field>
                </v-col>
              </v-row>
            </v-from>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogBonuses = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="createBonus()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog :retain-focus="false" v-model="dialogInvoiceCost" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Dodawanie Faktury Kosztowej</span>
            </v-card-title>
            <v-card-text>
              <v-from>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Kwota Brutto na Fakturze" v-model="invoiceCostPrice" placeholder="Kwota Brutto na Fakturze"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Opis" v-model="invoiceCostDescription" placeholder="Opis"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select label="% Zwrotu" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="taxType"
                      :items="taxTypes" item-text="text" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Tydzień Rozliczeniowy" v-model="invoiceWeekStr" type="week"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input accept="application/pdf" v-model="invoiceCostFile" type="file" refs="file" label="Załącznik"></v-file-input>
                  </v-col>
                </v-row>
              </v-from>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogInvoiceCost = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="createCostInvoice()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog :retain-focus="false" v-model="dialogContracts" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Dodawanie Dokumentu</span>
            </v-card-title>
            <v-card-text>
              <v-from>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Opis" v-model="descriptionContract" required placeholder="Opis"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                      <v-select label="Rodzaj Dokumentu" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="contractType" :items="contractTypes"
                        item-text="text" item-value="id"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input accept="application/pdf" v-model="contractFile" type="file" refs="file" label="Umowa"></v-file-input>
                  </v-col>
                </v-row>
              </v-from>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogContracts = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="createContract()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<v-dialog v-model="dashboardDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
<v-card>
  <v-card-title>
    <span class="text-h5">Szczegóły Rozliczenia</span>
  </v-card-title>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto" color="dark lighten-4" max-width="600">
            <v-card-title>
              <v-icon color="green" class="mr-12" size="64">
                mdi-cash-check
              </v-icon>
              <v-row>
                <v-col cols="12">
                  <div class="text-caption text-left green--text text-uppercase">
                    Twój Przelew: Bolt
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <span class=" text-h3 font-weight-black" v-text="boltEarn"></span>
                    <strong>PLN</strong>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto" color="dark lighten-4" max-width="600">
            <v-card-title>
              <v-icon color="green" class="mr-12" size="64">
                mdi-cash-check
              </v-icon>
              <v-row>
                <v-col cols="12">
                  <div class="text-caption green--text text-uppercase">
                    Twój Przelew: Uber - Taxi
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <span class="text-h3 font-weight-black" v-text="uberTaxiEarn"></span>
                    <strong>PLN</strong>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto" color="dark lighten-4" max-width="600">
            <v-card-title>
              <v-icon color="green" class="mr-12" size="64">
                mdi-cash-check
              </v-icon>
              <v-row>
                <v-col cols="12">
                  <div class="text-caption green--text text-uppercase">
                    Twój Przelew: Uber - Eats
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <span class="text-h3 font-weight-black" v-text="uberEatsEarn"></span>
                    <strong>PLN</strong>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto" color="dark lighten-4" max-width="600">
            <v-card-title>
              <v-icon color="green" class="mr-12" size="64">
                mdi-cash-check
              </v-icon>
              <v-row>
                <v-col cols="12">
                  <div class="text-caption green--text text-uppercase">
                    Twój Przelew: FreeNow
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <span class="text-h3 font-weight-black" v-text="freeNowEarn"></span>
                    <strong>PLN</strong>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto" color="dark lighten-4" max-width="600">
            <v-card-title>
              <v-icon color="red" class="mr-12" size="64">
                mdi-cash-remove
              </v-icon>
              <v-row>
                <v-col cols="12">
                  <div class="text-caption red--text text-uppercase">
                    Koszt Rozliczenia
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <span class="text-h3 font-weight-black" v-text="settlementCost"></span>
                    <strong>PLN</strong>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto" color="dark lighten-4" max-width="600">
            <v-card-title>
              <v-icon color="red" class="mr-12" size="64">
                mdi-cash-remove
              </v-icon>
              <v-row>
                <v-col cols="12">
                  <div class="text-caption red--text text-uppercase">
                    Koszt Księgowo-Administracyjy
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <span class="text-h3 font-weight-black" v-text="administrationCost"></span>
                    <strong>PLN</strong>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto" color="dark lighten-4" max-width="600">
            <v-card-title>
              <v-icon color="red" class="mr-12" size="64">
                mdi-cash-remove
              </v-icon>
              <v-row>
                <v-col cols="12">
                  <div class="text-caption red--text text-uppercase">
                    Koszt ZUS
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <span class="text-h3 font-weight-black" v-text="zusCost"></span>
                    <strong>PLN</strong>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto" color="dark lighten-4" max-width="600">
            <v-card-title>
              <v-icon color="green" class="mr-12" size="64">
                mdi-cash-fast
              </v-icon>
              <v-row>
                <v-col cols="12">
                  <div class="text-caption green--text text-uppercase">
                    Łączny Przelew
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="text-right">
                    <span class="text-h3 font-weight-black" v-text="wireTotal"></span>
                    <strong>PLN</strong>
                  </div>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="dialog = false">
      Anuluj
    </v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
        <v-dialog :retain-focus="false" v-model="smsDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Wysyłanie SMS</span>
            </v-card-title>
            <v-card-text>              
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Treść" v-model="smsDescription" required placeholder="Treść"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']">
                    </v-text-field>
                  </v-col>
                </v-row>              
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="smsDialog = false">
                Anuluj
              </v-btn>
              <v-btn color="blue darken-1" text @click="sendSms()">
                Zapisz
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">        
          <v-toolbar flat color="primary" dark>
                                  <v-btn icon dark @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
            <v-toolbar-title>{{name}}</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical style="height:100vh;">
            <v-tab>
              <v-icon left>
                mdi-gas-station
              </v-icon>
              Historia DKV
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-car-info
              </v-icon>
              Historia Aut
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-receipt-text
              </v-icon>
              Historia Wypłat
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-ticket-account
              </v-icon>
              Historia Kar
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-handshake
              </v-icon>
              Dokumenty
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-receipt
              </v-icon>
              Faktury Kosztowe
            </v-tab>
            <v-tab>
              <v-icon left>
                mdi-message-processing
              </v-icon>
              SMS
            </v-tab>
            <v-tab @click="showEdit()" >
              <v-icon left>
                mdi-cog
              </v-icon>
              Konfiguracja
            </v-tab>    
            <v-tab-item>
              <v-card flat>
                <v-data-table :headers="dkvHeaders" :items="dkv" :options.sync="dkvOptions"
                  :server-items-length="totalRecordsDkv" :loading="dkvLoading" class="elevation-1">
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
                <v-card-title>
                  Historia Aut
                </v-card-title>
              <v-card flat>
                <v-data-table :headers="carHeaders" :items="carHistory" :options.sync="carOptions"
                  :server-items-length="totalRecordCar" :loading="carLoading" class="elevation-1">
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title>
                  Wypłaty                                    
                </v-card-title>
                <v-data-table :headers="invoiceHeaders" :items="invoices" :options.sync="invoiceOptions" :server-items-length="totalRecordsInvoice"
                  :loading="invoiceLoading" class="elevation-1">
                  <template v-slot:item.actions="{ item }">
                    <v-btn class="primary">
                      <v-icon small class="pt-1" @click="getDashboard(item.id)">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
              <v-card flat>
                <v-card-title>
                  Kary i Bonusy
                  <v-spacer></v-spacer>
                  <v-btn class="primary" @click="showDialog('bonuses')">Dodaj</v-btn>
                </v-card-title>
                <v-data-table :headers="bonusHeaders" :items="bonuses" :options.sync="bonusOptions" :server-items-length="totalRecordsBonus"
                  :loading="bonusLoading" class="elevation-1">
                  <template v-slot:item.actions="{ item }">
                    <v-btn class="error" @click="deleteBonus(item.id)">
                      <v-icon small class="pt-1">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item >
                  <v-tab-item :eager="true">
                    <v-card flat>
                      <v-card-title>
                        Dokumenty
                        <v-spacer></v-spacer>
                        <v-btn class="primary" @click="showDialog('contracts')">Dodaj</v-btn>
                      </v-card-title>
                      <v-data-table :headers="dealHeaders" :items="deals" :options.sync="dealOptions"
                        :server-items-length="totalRecordsDeal" :loading="contractLoading" class="elevation-1">
                        <template v-slot:item.actions="{ item }">
                          <v-btn class="primary" @click="showDeal(item.id)">
                            <v-icon small class="pt-1">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:item.delActions="{ item }">
                          <v-btn class="error" @click="deleteContract(item.id)">
                            <v-icon small class="pt-1">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title>
                  Faktury Kosztowe
                        <v-spacer></v-spacer>
                        <v-btn class="primary" @click="showDialog('invoiceCost')">Dodaj</v-btn>
                </v-card-title>
                <v-data-table :headers="costinvoiceHeaders" :items="costinvoices" :options.sync="costinvoiceOptions"
                  :server-items-length="totalRecordsInvoiceCost" :loading="invoiceLoadingCost" class="elevation-1">
                  <template v-slot:item.actions="{ item }">
                    <v-btn class="primary" @click="showInvoiceCost(item.id)">
                      <v-icon small class="pt-1">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item :eager="true">
              <v-card flat>
                <v-card-title>
                  SMS
                  <v-spacer></v-spacer>
                  <v-btn class="primary" @click="showDialog('sms')">Wyślij SMS</v-btn>
                </v-card-title>
                <v-data-table :headers="smsHeaders" :items="smsHistory" :options.sync="smsOptions"
                  :server-items-length="totalRecordsSms" :loading="smsLoading" class="elevation-1">
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs>
      </v-dialog>

    <v-card-title>
      Kierowcy
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Szukaj" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="showDialog()">Dodaj</v-btn>
    </v-card-title>
    <v-data-table :headers="headers" :items="drivers" :options.sync="options" :server-items-length="totalRecords"
      :loading="loading" :search="search" class="elevation-1">
      <template v-slot:item.actions="{ item }">
        <v-btn class="primary" @click="getDriverById(item.id)">
          <v-icon small class="pt-1">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actionsDel="{ item }">
        <v-btn class="error" @click="deleteDriver(item.id)">
          <v-icon small class="pt-1">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
  name: "DriverDetails",
  data: () => ({
    // create
    search: '',
    password:'',
    street:'',
    dialogCreateLoading:false,
    streetExtraInfo:'',
    city:'',
    licenceNumber:'',
    abroadBankAccount:false,
    cashPayments:false,
    isUnder26:false,
    isStudent:false,
    isEmployed: false,
    accountNo:'',
    firstName:'',
    lastName:'',
    phone:'',
    email:'',
    paymentSchedule:'',
    cooperationType:'',    
    boltId:'',
    uberId:'',
    uberEatsId:'',
    freeId:'',    
    departaments:[],
    name:'Nazwa Kierowcy',
    action:'Dodawanie',
    departament: '',
    contractType:'other',
    contractTypes:[
      {
      id:'other',
      text:'Inne'
    }, {
        id: 'auto',
        text: 'Dowód Rejestracyjny'
      },
      {
        id: 'license',
        text: 'Prawo Jazdy'
      },
      {
        id: 'medical',
        text: 'Badania Medyczne'
      },
      {
        id: 'psycho',
        text: 'Psychotesty'
      }, {
        id: 'oc',
        text: 'Ubezpieczenie'
      }],
    paymentsSchedules: [{
      id: 'week',
      text: 'Tygodniowy'
    },
    {
      id: 'two_week',
      text: '2 Tygodnie'
      }, {
      id: 'month',
      text: 'Miesiąc'
      }],  
      cooperationTypes:[ {
          id: 'b2b_vat',
          text: 'B2B z VAT'
        },
        {
          id: 'b2b_no_vat',
          text: 'B2B Bez VAT'
        },
        {
          id: 'connection',
          text: 'Podpięcie'
        },
        {
          id: 'mob',
          text: '50/50'
        },
        {
          id: 'rent',
          text: 'Wynajmujący'
        }        
      ], 
        // deals
        dealHeaders:[
          {
            text: 'Data Dodania',
            align: 'start',
            value: 'created_at',
          },
          {
            text: 'Opis',
            align: 'start',
            value: 'description',
          },
          { text: "", value: "actions", sortable: false },    
          { text: "", value: "delActions", sortable: false },           
        ],
        deals:[],
        dealOptions:{},
        totalRecordsDeal:0,
        // sms
    smsHeaders: [{
      text: 'Data Wysłania',
      align: 'start',
      value: 'created_at',
    },
      {
        text: 'Numer Telefonu',
        align: 'start',
        value: 'phone',
      },   
    {
      text: 'Treść',
      align: 'start',
      value: 'description',
    }],    
    smsHistory:[],
    smsOptions:{},
    smsDescription:'',
    totalRecordsSms:0,
    smsLoading :false,
    smsDialog:false,  
      //car
    carHeaders: [
      {
        text: 'Auto',
        align: 'start',
        value: 'car',
        sortable:false,
      },    
    {
      text: 'Data Rozpoczęcia',
      align: 'start',
      value: 'startDate',
    },
    {
      text: 'Data Zakończenia',
      align: 'start',
      value: 'endDate',
    },    
  ],
  carHistory:[],
  carOptions:{},
  totalRecordCar:0,
  carLoading:false,     
        // invoice    
    invoiceHeaders: [
      { text: 'Data Wygenerowania Wypłaty', value: 'created_at' },
      {
        text: 'Suma Do Przelewu',
        align: 'start',
        value: 'total',
        sortable: false,
      },
      { text: '', value: 'actions', sortable: false, },
    ],
    invoices: [],
    invoiceOptions: {},
    totalRecordsInvoice: 0,     
    // invoice    
    dkvHeaders: [
      {
        text: 'Karta',
        align: 'start',
        value: 'card',
      },        
    {
      text: 'Data Dodania',
      align: 'start',
      value: 'created_at',
    },
    {
      text: 'Rodzaj',
      align: 'start',
      value: 'type',
    },
    {
      text: 'Kwota',
      align: 'start',
      value: 'price',
    },  
    { text: "", value: "actions", sortable: false }],
    dkv: [],
    dkvLoading:false,
    dkvOptions: {},
    totalRecordsDkv: 0,   
    invoiceLoading:false,
    bonusLoading: false,
    contractLoading: false,  
    // settlement
    boltEarn: 0,
    uberTaxiEarn: 0,
    uberEatsEarn: 0,
    freeNowEarn: 0,
    settlementCost: 0,
    zusCost: 0,
    wireTotal: 0,
    administrationCost:0,
    dashboardDialog:false,    
        // bonus 
    bonusWeek:'',
    bonusHeaders: [{
        text: 'Data Dodania',
        align: 'start',
        value: 'created_at',
      },
      {
        text: 'Opis',
        align: 'start',
        value: 'description',
      },
      {
        text: 'Rodzaj Bonusu',
        align: 'start',
        value: 'type',
      },
      {
        text: 'Kwota',
        align: 'start',
        value: 'price',
      },
      { text: "", value: "actions", sortable: false }],
        bonuses:[],
        bonusOptions:{},
        totalRecordsBonus:0,
        priceBonus:0,
        descriptionBonus:'',
        balanceType:null,
        balanceTypes: [{
          id: 'positive',
          text: 'Bonus'
        },
          {
            id: 'negative',
            text: 'Kara'
          },
          {
            id: 'rent',
            text: 'Wypożyczenie'
          }          
        ],
        dialogBonuses:false,
        dialogContracts:false,
        descriptionContract:'',
        contractFile:'',
        // 
        dialog:false,
        dialogCreate:false,
        loading:false,
        options:{},
        totalRecords:0,
        // tbl
        drivers:[],
        num:0,
    headers: [
      {
        text: 'Imię',
        align: 'start',
        value: 'firstName',
      },
      {
        text: 'Nazwisko',
        align: 'start',
        value: 'lastName',
      },
      {
        text: 'Email',
        align: 'start',
        value: 'email',
      },
      {
        text: 'Telefon',
        align: 'start',
        value: 'phone',
      },          
      { text: "", value: "actions", sortable: false },      
      { text: "", value: "actionsDel", sortable: false }, 
    ],  
      // invoices
      invoiceWeekStr:'',
    totalRecordsInvoiceCost:0,
    costinvoices:[],
    costinvoiceOptions:{},
    costinvoiceHeaders:[
      {
        text: 'Data Dodania',
        align: 'start',
        value: 'created_at',
      },
      {
        text: 'Opis',
        align: 'start',
        value: 'description',
      },       
      {
        text: 'Suma na fakturze',
        align: 'start',
        value: 'totalPrice',
      }, 
      {
        text: '% Zwrotu',
        align: 'start',
        value: 'taxPrice',
      },
      {
        text: 'Do Zwrotu',
        align: 'start',
        value: 'returnPrice',
      },                        
      { text: "", value: "actions", sortable: false },    
    ],
    invoiceCostPrice:0,
    invoiceCostDescription:'',
    taxType:null,
    taxTypes: [{
      id: 'tax_vat_fuel_range_1',
      text: 'I Stopień - Paliwo'
    },
      {
        id: 'tax_vat_fuel_range_2',
        text: 'II Stopień - Paliwo'
      },
      {
        id: 'tax_vat_fuel_range_3',
        text: 'III Stopień - Paliwo'
      }  ,
      {
        id: 'tax_vat_full_range_1',
        text: 'I Stopień - Inne'
      },
      {
        id: 'tax_vat_full_range_2',
        text: 'II Stopień - Inne'
      },
      {
        id: 'tax_vat_full_range_3',
        text: 'III Stopień - Inne'
      }                              
    ],
    invoiceCostFile:'',
    invoiceLoadingCost:false,
    dialogInvoiceCost:false,
  }),
  watch: {
    options: {
      async handler() {
        await this.loadDrivers()
      },
      deep: true,
    },
    dkvOptions: {
      async handler() {
        await this.getDkvHistory()
      },
      deep: true,
    },  
    bonusOptions: {
      async handler() {
        await this.getBonusHistory()
      },
      deep: true,
    }, 
    dealOptions: {
      async handler() {
        await this.getContractsHistory()
      },
      deep: true,
    },
    costinvoiceOptions: {
      async handler() {
        await this.getInvoiceCostHistory()
      },
      deep: true,
    }, 
    invoiceOptions:{
      async handler() {
        await this.getSettlemens()
      },
      deep: true,
    },
    carOptions: {
      async handler() {
        await this.getCarsHistory()
      },
      deep: true,
    },     
    smsOptions: {
      async handler() {
        await this.getSmsHistory()
      },
      deep: true,
    },                        
    async search() {
      await this.loadDrivers()
    }    
  },    
  methods: {
    async showDialog(num) {  
      if(!num){
        this.action = 'Dodawanie'
        this.dialogCreate = true
        this.currentDriverId = null
        await this.getDepartamentSelect() 
      }else if(num=='bonuses'){
        this.dialogBonuses = true
      } else if (num == 'contracts') {
        this.dialogContracts = true
      }else if (num == 'invoiceCost') {
        this.dialogInvoiceCost = true
      }  
      else if (num == 'sms') {
        this.smsDialog = true
      }          
   
    },
    async createBonus(){
      this.bonusLoading = true
      try{
        if (!this.bonusWeek.trim().length) {
          throw ({ response: { statusText: 'Brak Wybranego Tygodnia' } })
        }          
        if (!this.descriptionBonus.trim().length) {
          throw ({ response: { statusText: 'Brak Opisu Rekodu' } })
        }  
        if (!this.balanceType.trim().length) {
          throw ({ response: { statusText: 'Brak Rodzaju Rekodu' } })
        }  
        if (!this.priceBonus.trim().length) {
          throw ({ response: { statusText: 'Brak Kwoty' } })
        }  
        if (!this.descriptionBonus.trim().length) {
          throw ({ response: { statusText: 'Brak Opisu Rekodu' } })
        }                                   
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/bonuses",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            price: this.priceBonus,
            description: this.descriptionBonus,
            type: this.balanceType,
            driver: this.currentDriverId,
            weekNo:this.bonusWeek
          },
        });
        this.bonusWeek = ''
        this.priceBonus = '';
        this.descriptionBonus = ''
        this.dialogBonuses = false
        this.balanceType = null    
        await this.getBonusHistory()      
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.bonusLoading = false
    },
    async getDashboard(id){
      await this.toggleLoading(true)
      this.dashboardDialog = true
      const details = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + `/settlements/get-dashboard-for-id-admin/${id}/${this.currentDriverId}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.boltEarn = details.data.data.boltSettlement || '0,00'
      this.uberTaxiEarn = details.data.data.uberSettlements || '0,00'
      this.uberEatsEarn = details.data.data.uberEatsSettlements || '0,00'
      this.freeNowEarn = details.data.data.freeNowSettlements || '0,00'
      this.zusCost = details.data.data.zusSettlements.length ? (details.data.data.zusSettlements[0].zusPrice / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" }) : '0,00'
      this.wireTotal = details.data.data.result.length ? ((details.data.data.result[0].totalRent + details.data.data.result[0].totalReturn + details.data.data.result[0].totalZus) / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" }) : '0,00'
      this.administrationCost = details.data.data.result.length ? (details.data.data.result[0].administrationTax / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" }) : '0,00'
      this.settlementCost = details.data.data.result.length ? (details.data.data.result[0].settlementCost / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" }) : '0,00'
      await this.toggleLoading(false)  
    },
    async sendSms() {
      this.smsLoading = true
      try {
        if (!this.smsDescription.trim().length) {
          throw ({ response: { statusText: 'Brak Treści SMS' } })
        }         
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/drivers/send-sms",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            smsText: this.smsDescription,
            driver: this.currentDriverId
          },
        });
        this.smsDescription = ''
        this.smsDialog = false        
        await this.getSmsHistory()
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.smsLoading = false
    },    
    async showDeal(id){
      try {
        const file = await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + `/contracts/get-document/${id}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: "blob"          
        });  
        let blob = new Blob([file.data], { type: file.data.type }),
          url = window.URL.createObjectURL(blob)

        window.open(url)              
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
    },
    async showInvoiceCost(id) {
      try {
        const file = await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + `/invoices/show-document/${id}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: "blob"
        });
        let blob = new Blob([file.data], { type: file.data.type }),
          url = window.URL.createObjectURL(blob)

        window.open(url)
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
    },    
    async createContract() {
      this.contractLoading = true
      try {
        const formData = new FormData()
        try{
          formData.append("file", this.contractFile, this.contractFile.name);
        }catch(e){
          console.log('Missing File')
        }
        
        formData.append('driver', this.currentDriverId)
        if (!this.descriptionContract.trim().length) {
          throw ({ response: { statusText: 'Brak Opisu Dokumentu!' } })
        } 
        formData.append('description', this.descriptionContract)  
        formData.append('contractType', this.contractType)        
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/contracts/upload-document",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: formData,
        });        
        this.descriptionContract = ''
        this.contractType='other'
        this.dialogContracts = false        
        await this.getContractsHistory()
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.contractLoading = false
    },  
    async createCostInvoice(){
      this.invoiceLoadingCost = true
      try {
        const formData = new FormData()
        try{
          formData.append("file", this.invoiceCostFile, this.invoiceCostFile.name);
        }catch(e){
          console.log('missing file')
        }
        if (!this.invoiceWeekStr.trim().length) {
          throw ({ response: { statusText: 'Brak Wybranego Tygodnia!' } })
        }  
        if (!this.invoiceCostPrice.trim().length) {
          throw ({ response: { statusText: 'Brak Ceny!' } })
        } 
        if (!this.invoiceCostDescription.trim().length) {
          throw ({ response: { statusText: 'Brak Opisu Faktury!' } })
        } 
        if (!this.taxType.trim().length) {
          throw ({ response: { statusText: 'Brak Stawki Zwrotu Podatku!' } })
        }                                
        formData.append('driver', this.currentDriverId)
        formData.append('weekStr', this.invoiceWeekStr)
        formData.append('totalPrice', this.invoiceCostPrice)
        formData.append('description', this.invoiceCostDescription)
        formData.append('taxRange', this.taxType)
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/invoices/upload-document",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: formData,
        });
        this.invoiceCostFile = null
        this.invoiceCostPrice = 0
        this.invoiceWeekStr=''
        this.invoiceCostDescription = ''
        this.taxType = null        
        this.dialogInvoiceCost = false
        await this.getInvoiceCostHistory()
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.invoiceLoadingCost = false      
    },  
    async getCarsHistory(){
      this.carLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.carOptions
        const cars = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/drivers/get-car-history/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'startDate'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        cars.data.data.items.forEach((item) => {
          item.car = item.car.plate          
          item.startDate = dayjs(item.startDate).format('YYYY-MM-DD')
          item.endDate = dayjs(item.endDate).format('YYYY-MM-DD')
        })
        this.carHistory = cars.data.data.items
        this.itemsPerPage = cars.data.data.meta.itemsPerPage
        this.page = cars.data.data.meta.currentPage
        this.totalRecordCar = cars.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.carLoading = false       
    },
    async getDkvHistory(){
      this.dkvLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.dkvOptions
        const dkvs = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/dkv/list-dkv-history-for-driver/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dkvs.data.data.items.forEach((item) => {
          item.card = item.card.dkvNo
          item.type = item.type == 'positive' ? 'Doładowanie' : 'Wydatek'
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm') 
          item.price = (item.price / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });         
        })
        this.dkv = dkvs.data.data.items
        this.itemsPerPage = dkvs.data.data.meta.itemsPerPage
        this.page = dkvs.data.data.meta.currentPage
        this.totalRecordsDkv = dkvs.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.dkvLoading = false 
    },
    async getSmsHistory() {
      this.smsLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.smsOptions
        const sms = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/drivers/sms-history/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        sms.data.data.items.forEach((item) => {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
        })
        this.smsHistory = sms.data.data.items
        this.itemsPerPage = sms.data.data.meta.itemsPerPage
        this.page = sms.data.data.meta.currentPage
        this.totalRecordsSms = sms.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.smsLoading = false
    },    
    async getBonusHistory() {
      this.dkvLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.bonusOptions
        const bonus = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/bonuses/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        bonus.data.data.items.forEach((item) => { 
          switch(item.type){
            case 'positive':
              item.type = 'Premia'
              break;
            case 'rent':
              item.type = 'Wypożyczenie'
              break;
            case 'negative':
              item.type = 'Kara'
              break;                           
          }  
          item.price = (item.price / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });                          
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')          
        })
        this.bonuses = bonus.data.data.items
        this.itemsPerPage = bonus.data.data.meta.itemsPerPage
        this.page = bonus.data.data.meta.currentPage
        this.totalRecordsBonus = bonus.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.dkvLoading = false
    },   
    async getContractsHistory() {
      this.contractLoading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.dealOptions
        const contracts = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/contracts/get-documents/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        contracts.data.data.items.forEach((item) => {          
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
        })
        this.deals = contracts.data.data.items
        this.itemsPerPage = contracts.data.data.meta.itemsPerPage
        this.page = contracts.data.data.meta.currentPage
        this.totalRecordsDeal = contracts.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.contractLoading = false
    }, 
    async getSettlemens(){
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.invoiceOptions
        const invoices = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/settlements/get-payments-for-driver/${this.currentDriverId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        invoices.data.data.items.forEach((item) => {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.total = ((item.totalZus + item.totalRent + item.totalReturn) / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });
        })        
        this.invoices = invoices.data.data.items
        this.itemsPerPage = invoices.data.data.meta.itemsPerPage
        this.page = invoices.data.data.meta.currentPage
        this.totalRecordsInvoice = invoices.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }      
      
      this.invoiceLoading = false
    },
    async getInvoiceCostHistory() {
      this.invoiceLoadingCost = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.costinvoiceOptions
        console.log(sortBy)
        const invoices = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/invoices/get-documents/${this.currentDriverId}?sort=${sortBy.length != 0 ? sortBy : 'created_at'}&direction=${sortDesc?sortDesc:'true'}&page=${page?page:1}&limit=${itemsPerPage?itemsPerPage:10}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        invoices.data.data.items.forEach((item) => {
          item.totalPrice = (item.totalPrice / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" }); 
          item.taxPrice = (item.taxPrice / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" }); 
          item.returnPrice = (item.returnPrice / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });         
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
        })
        this.costinvoices = invoices.data.data.items
        this.itemsPerPage = invoices.data.data.meta.itemsPerPage
        this.page = invoices.data.data.meta.currentPage
        this.totalRecordsInvoiceCost = invoices.data.data.meta.totalItems
      } catch (e) {
        console.log(e)        
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.invoiceLoadingCost = false
    },            
    async showEdit(){      
      this.action = 'Edycja'
      this.dialogCreate = true
      await this.getDepartamentSelect()
      await this.toggleLoading(true);
      this.dialogCreateLoading =true
      try {
        let driver = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/drivers/" + this.currentDriverId,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });        
            this.firstName= driver.data.data.firstName,
            this.lastName = driver.data.data.lastName,
            this.isUnder26 = driver.data.data.isUnder26
            this.isStudent = driver.data.data.isStudent
            this.isEmployed = driver.data.data.isEmployed        
            this.email = driver.data.data.email,
            this.phone = driver.data.data.phone,
            this.departament = driver.data.data.departament,
            this.boltId = driver.data.data.boltId,   
            this.uberId = driver.data.data.uberId,  
            this.uberEatsId = driver.data.data.uberEatsId,  
            this.freeId = driver.data.data.freeId,            
            this.street = driver.data.data.street,            
            this.streetExtraInfo = driver.data.data.streetExtraInfo,            
            this.city = driver.data.data.city,            
            this.licenceNumber = driver.data.data.licenceNumber,            
            this.abroadBankAccount = driver.data.data.abroadBankAccount,            
            this.cashPayments = driver.data.data.cashPayments,            
            this.accountNo = driver.data.data.accountNo,            
            this.paymentSchedule = driver.data.data.paymentSchedule,            
            this.cooperationType = driver.data.data.cooperationType          
      } catch (e) {
        console.log(e);
        this.dialogCreate = false;
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.dialogCreateLoading = false
      await this.toggleLoading(false);      
    },
    async deleteBonus(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Bonus?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/bonuses/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.getBonusHistory()
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.loadDepartaments()
    }, 
    async deleteContract(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Dokument?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/contracts/delete-document" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });            
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.getContractsHistory()
    },       
    async deleteDriver(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Usunąć Kierowcę?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/drivers/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });            
          }
        });
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.loadDrivers()
    },       
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },  
    async getDriverById(id){
      await this.toggleLoading(true);
      this.currentDriverId = id
      this.dialog = true
      try{
        let driver = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/drivers/" + this.currentDriverId,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });         
        this.name = driver.data.data.firstName+' '+driver.data.data.lastName
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      if(this.num!=0){
        await this.getDkvHistory()
        await this.getSettlemens()
        await this.getBonusHistory()
        await this.getContractsHistory()
        await this.getInvoiceCostHistory()
        await this.getCarsHistory()
        await this.getSmsHistory()
      }
      this.num=1      
    },
    async getDepartamentSelect() {
      await this.toggleLoading(true);
      try {
        let dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/departaments/list-departaments?itemsPerPage=100&sort=departamentName&direction=false",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.departaments = dep.data.data.items;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    }, 
    async validateDriverForm(){
      if(!this.firstName.trim().length){
        throw({response:{
          statusText:'Uzupełnij Imię'
        }})
      }
      if (!this.currentDriverId) {
        if (!this.password.trim().length) {
          throw ({
            response: {
              statusText: 'Uzupełnij Hasło'
            }
          })
        }  
      }    
      if(!this.lastName.trim().length){
        throw({response:{
          statusText:'Uzupełnij Nazwisko'
        }})
      }
      if(!this.email.trim().length){
        throw({response:{
          statusText:'Uzupełnij E-mail'
        }})
      }
      if(!this.phone.trim().length){
        throw({response:{
          statusText:'Uzupełnij Telefon'
        }})
      }
      if(!this.departament.trim().length){
        throw({response:{
          statusText:'Uzupełnij Oddział'
        }})
      }
      if(!this.boltId.trim().length){
        throw({response:{
          statusText:'Uzupełnij ID BOLT'
        }})
      }
      if(!this.uberId.trim().length){
        throw({response:{
          statusText:'Uzupełnij ID UBER'
        }})
      }
      if(!this.uberEatsId.trim().length){
        throw({response:{
          statusText:'Uzupełnij ID UBER EATS'
        }})
      }
      if(!this.freeId.trim().length){
        throw({response:{
          statusText:'Uzupełnij ID FREENOW'
        }})
      }
      if(!this.street.trim().length){
        throw({response:{
          statusText:'Uzupełnij Adres'
        }})
      }
      if(!this.streetExtraInfo.trim().length){
        throw({response:{
          statusText:'Uzupełnij Adres 2'
        }})
      }
      if(!this.city.trim().length){
        throw({response:{
          statusText:'Uzupełnij Miasto'
        }})
      }
      if(!this.licenceNumber.trim().length){
        throw({response:{
          statusText:'Uzupełnij Numer Licencji'
        }})
      }
      if(!this.accountNo.trim().length){
        throw({response:{
          statusText:'Uzupełnij Numer Konta'
        }})
      }
      if(!this.paymentSchedule.trim().length){
        throw({response:{
          statusText:'Uzupełnij Częstotliwość Rozliczeń'
        }})
      }
      if(!this.cooperationType.trim().length){
        throw({response:{
          statusText:'Uzupełnij Sposób Rozliczeń'
        }})
      }
    },
    async createDriver() {
      await this.toggleLoading(true);
      let method = "";
      let url = "";
      if (this.currentDriverId) {
        method = "patch";
        url = process.env.VUE_APP_ROOT_API + "/drivers/" + this.currentDriverId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/drivers";
      }
      try {
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            password:this.password||null,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,            
            phone: this.phone,            
            departament: this.departament,
            boltId: this.boltId,
            uberId: this.uberId,
            uberEatsId: this.uberEatsId,
            isUnder26:this.isUnder26,
            isStudent: this.isStudent,
            isEmployed: this.isEmployed,
            freeId: this.freeId,
            street: this.street,
            streetExtraInfo: this.streetExtraInfo,
            city: this.city,
            licenceNumber: this.licenceNumber,
            abroadBankAccount: this.abroadBankAccount,
            cashPayments: this.cashPayments,
            accountNo: this.accountNo,
            paymentSchedule: this.paymentSchedule,
            cooperationType: this.cooperationType,
          },
        });
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.phone = ''
        this.departament = null
        this.boltId = ''
        this.uberId = ''
        this.isEmployed = false
        this.isUnder26 = false
        this.isStudent = false
        this.uberEatsId =''
        this.freeId = ''
        this.street = ''
        this.streetExtraInfo = ''
        this.city = ''
        this.licenceNumber = ''
        this.abroadBankAccount = false
        this.cashPayments = false
        this.accountNo = '';
        this.password = '';
        this.paymentSchedule=null
        this.cooperationType=null
        this.dialogCreate = false        
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.loadDrivers()
    },            
    async loadDrivers() {
      this.loading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        console.log(sortBy, sortDesc, page, itemsPerPage)
        const drivers = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/drivers?sort=${sortBy.length ? sortBy : 'lastName'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.drivers = drivers.data.data.items
        this.itemsPerPage = drivers.data.data.meta.itemsPerPage
        this.page = drivers.data.data.meta.currentPage
        this.totalRecords = drivers.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loading = false
    },        
  }  
}
</script>
