<template>
    <v-card>
        <v-dialog :loading="dialogCreateLoading" v-model="dialogCreate" fullscreen hide-overlay
            transition="dialog-bottom-transition">
            <v-card>
                <!-- <v-card-title>
                      <span class="text-h5">{{action}} Kierowcy</span>
                    </v-card-title> -->
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialogCreate = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{action}} Kierowcy</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field label="Imię" v-model="firstName" required placeholder="Imię"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Nazwisko" v-model="lastName" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                    required placeholder="Nazwisko"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Telefon" v-model="phone" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                                    placeholder="Telefon"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Hasło" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="password"
                                    v-model="password" placeholder="Hasło"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="E-mail" :rules="[(v) => !!v || 'Uzupełnij Pole']" required type="email"
                                    v-model="email" placeholder="E-mail"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Adres" v-model="street" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                                    placeholder="Adres"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Adres 2" v-model="streetExtraInfo"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']" required placeholder="Adres 2">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Miasto" v-model="city" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                                    placeholder="Miasto">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-select label="Oddział" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="departament"
                                    :items="departaments" item-text="departamentName" item-value="id"></v-select>
                            </v-col>
                            <v-divider></v-divider>
                            <v-col cols="2">
                                <v-checkbox label="Konto za Granicą?" v-model="abroadBankAccount"></v-checkbox>
                            </v-col>
                            <v-col cols="2">
                                <v-checkbox label="Wypłaty Gotówką" v-model="cashPayments"></v-checkbox>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field label="Numer Konta" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="accountNo">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-select label="Częstotliwość Wypłat" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                    v-model="paymentSchedule" :items="paymentsSchedules" item-text="text" item-value="id">
                                </v-select>
                            </v-col>
                            <v-col cols="2">
                                <v-checkbox label="Poniżej 26 Roku Życia?" v-model="isUnder26"></v-checkbox>
                            </v-col>
                            <v-col cols="2">
                                <v-checkbox label="Zatrudniony" v-model="isEmployed"></v-checkbox>
                            </v-col>
                            <v-col cols="2">
                                <v-checkbox label="Student" v-model="isStudent"></v-checkbox>
                            </v-col>
                            <v-col cols="3">
                                <v-select label="Rodzaj Współpracy" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                    v-model="cooperationType" :items="cooperationTypes" item-text="text" item-value="id">
                                </v-select>
                            </v-col>
                            <v-divider></v-divider>
                            <v-col cols="12">
                                <v-text-field label="Numer Licencji" placeholder="Numer Licencji" v-model="licenceNumber">
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="ID Bolt" placeholder="ID Bolt" v-model="boltId"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="ID Uber" placeholder="ID Uber" v-model="uberId"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="ID UberEats" placeholder="ID UberEats" v-model="uberEatsId"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field label="ID FreeNow" placeholder="ID FreeNow" v-model="freeId"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogCreate = false">
                        Anuluj
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="createDriver()">
                        Zapisz
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-title>
            Kierowcy do Akceptacji
            <v-spacer></v-spacer>
        </v-card-title>
    <v-data-table :headers="headers" :items="drivers" :options.sync="options" :server-items-length="totalRecords"
        :loading="loading" :search="search" class="elevation-1">
        <template v-slot:item.actions="{ item }">
            <v-btn class="primary" @click="showEdit(item.id)">
                <v-icon small class="pt-1">mdi-pencil</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    </v-card>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2";

export default {
    name: "DriverDetails",
    data: () => ({
        search: '',
        password: '',
        street: '',
        dialogCreateLoading: false,
        streetExtraInfo: '',
        city: '',
        licenceNumber: '',
        abroadBankAccount: false,
        cashPayments: false,
        isUnder26: false,
        isStudent: false,
        isEmployed: false,
        accountNo: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        paymentSchedule: '',
        cooperationType: '',
        boltId: '',
        uberId: '',
        uberEatsId: '',
        freeId: '',
        departaments: [],
        name: 'Akceptacja Kierowcy',
        action: 'Akceptacja',
        departament: '',   
        paymentsSchedules: [{
            id: 'week',
            text: 'Tygodniowy'
        },
        {
            id: 'two_week',
            text: '2 Tygodnie'
        }, {
            id: 'month',
            text: 'Miesiąc'
        }],
        cooperationTypes: [{
            id: 'b2b_vat',
            text: 'B2B z VAT'
        },
        {
            id: 'b2b_no_vat',
            text: 'B2B Bez VAT'
        },
        {
            id: 'connection',
            text: 'Podpięcie'
        },
        {
            id: 'mob',
            text: '50/50'
        },
        {
            id: 'rent',
            text: 'Wynajmujący'
        }
        ],              
    dialog: false,
    dialogCreate: false,
    loading: false,
    options: {},
    totalRecords: 0,
    // tbl
    drivers: [],
    num: 0,
    headers: [
        {
            text: 'Imię',
            align: 'start',
            value: 'firstName',
        },
        {
            text: 'Nazwisko',
            align: 'start',
            value: 'lastName',
        },
        {
            text: 'Email',
            align: 'start',
            value: 'email',
        },
        {
            text: 'Telefon',
            align: 'start',
            value: 'phone',
        },
        { text: "", value: "actions", sortable: false },        
    ],
    }),
    watch: {
        options: {
            async handler() {
                await this.loadDrivers()
            },
            deep: true,
        },
        async search() {
            await this.loadDrivers()
        }           
    } ,
    methods:{
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        }, 
        async showEdit(id) {
            this.currentDriverId = id
            this.action = 'Edycja'
            this.dialogCreate = true
            await this.getDepartamentSelect()
            await this.toggleLoading(true);
            this.dialogCreateLoading = true
            try {
                let driver = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/drivers/" + this.currentDriverId,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.firstName = driver.data.data.firstName,
                    this.lastName = driver.data.data.lastName,
                    this.isUnder26 = driver.data.data.isUnder26
                this.isStudent = driver.data.data.isStudent
                this.isEmployed = driver.data.data.isEmployed
                this.email = driver.data.data.email,
                    this.phone = driver.data.data.phone,
                    this.departament = driver.data.data.departament,
                    this.boltId = driver.data.data.boltId,
                    this.uberId = driver.data.data.uberId,
                    this.uberEatsId = driver.data.data.uberEatsId,
                    this.freeId = driver.data.data.freeId,
                    this.street = driver.data.data.street,
                    this.streetExtraInfo = driver.data.data.streetExtraInfo,
                    this.city = driver.data.data.city,
                    this.licenceNumber = driver.data.data.licenceNumber,
                    this.abroadBankAccount = driver.data.data.abroadBankAccount,
                    this.cashPayments = driver.data.data.cashPayments,
                    this.accountNo = driver.data.data.accountNo,
                    this.paymentSchedule = driver.data.data.paymentSchedule,
                    this.cooperationType = driver.data.data.cooperationType
            } catch (e) {
                console.log(e);
                this.dialogCreate = false;
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.dialogCreateLoading = false
            await this.toggleLoading(false);
        },  
        async createDriver() {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url: process.env.VUE_APP_ROOT_API + "/drivers/accept-driver/" + this.currentDriverId,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        password: this.password || null,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email,
                        phone: this.phone,
                        departament: this.departament,
                        boltId: this.boltId,
                        uberId: this.uberId,
                        uberEatsId: this.uberEatsId,
                        isUnder26: this.isUnder26,
                        isStudent: this.isStudent,
                        isEmployed: this.isEmployed,
                        freeId: this.freeId,
                        street: this.street,
                        streetExtraInfo: this.streetExtraInfo,
                        city: this.city,
                        licenceNumber: this.licenceNumber,
                        abroadBankAccount: this.abroadBankAccount,
                        cashPayments: this.cashPayments,
                        accountNo: this.accountNo,
                        paymentSchedule: this.paymentSchedule,
                        cooperationType: this.cooperationType,
                    },
                });
                this.firstName = ''
                this.lastName = ''
                this.email = ''
                this.phone = ''
                this.departament = null
                this.boltId = ''
                this.uberId = ''
                this.isEmployed = false
                this.isUnder26 = false
                this.isStudent = false
                this.uberEatsId = ''
                this.freeId = ''
                this.street = ''
                this.streetExtraInfo = ''
                this.city = ''
                this.licenceNumber = ''
                this.abroadBankAccount = false
                this.cashPayments = false
                this.accountNo = '';
                this.password = '';
                this.paymentSchedule = null
                this.cooperationType = null
                this.dialogCreate = false
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
            await this.loadDrivers()
        },               
        async loadDrivers() {
            this.loading = true
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.options                
                const drivers = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/drivers/to-accept?sort=${sortBy.length ? sortBy : 'lastName'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.drivers = drivers.data.data.items
                this.itemsPerPage = drivers.data.data.meta.itemsPerPage
                this.page = drivers.data.data.meta.currentPage
                this.totalRecords = drivers.data.data.meta.totalItems
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            this.loading = false
        },    
        async getDepartamentSelect() {
            await this.toggleLoading(true);
            try {
                let dep = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/departaments/list-departaments?itemsPerPage=100&sort=departamentName&direction=false",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.departaments = dep.data.data.items;
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
            }
            await this.toggleLoading(false);
        },             
    }
}
</script>
