<template>  
  <v-card>
    <v-card-title>
      Dashboard
      <v-spacer></v-spacer>
            <v-text-field label="Tydzień" v-model="weekNo" type="week" @change="loadDashboard()"></v-text-field>
            <v-spacer></v-spacer>
            <v-select @change="loadDashboard()" label="Oddział" v-model="departament" :items="departaments"
              item-text="departamentName" item-value="id"></v-select>
    </v-card-title>
  
  <v-row>
    <v-col cols="12">
      <v-container class="text-center">
        <h2>Platformy</h2>
      </v-container>
    </v-col>
    <v-col cols="3">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="warning" class="mr-12" size="64">
            mdi-arrow-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption warning--text text-uppercase">
              Bolt
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalBolt"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
  
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="warning" class="mr-12" size="64">
            mdi-arrow-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption warning--text text-uppercase">
              Uber - Taxi
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalUber"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
  
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="warning" class="mr-12" size="64">
            mdi-arrow-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption warning--text text-uppercase">
              Uber - Eats
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalUberEats"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
  
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="warning" class="mr-12" size="64">
            mdi-arrow-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption warning--text text-uppercase">
              FreeNow
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalFreeNow"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
  
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-container class="text-center">
        <h2>Zyski-Rozliczenia</h2>
      </v-container>
    </v-col>
    <v-col cols="4">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="green" class="mr-12" size="64">
            mdi-arrow-top-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption green--text text-uppercase">
              Łączny Koszt Księgowo-Administracyjy
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalAdministrationCost"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
    
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="green" class="mr-12" size="64">
            mdi-arrow-top-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption green--text text-uppercase">
              Łączny Koszt Rozliczeń
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalSettlementCost"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
    
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
<v-col cols="4">
  <v-card class="mx-auto" color="dark lighten-4" max-width="600">
    <v-card-title>
      <v-icon color="success" class="mr-12" size="64">
        mdi-arrow-right-thick
      </v-icon>
      <v-row align="start">
        <div class="text-caption success--text text-uppercase">
          Zysk
        </div>
        <div>
          <span class="text-h3 font-weight-black" v-text="profit"></span>
          <strong>PLN</strong>
        </div>
      </v-row>

      <v-spacer></v-spacer>
    </v-card-title>
  </v-card>
</v-col>
    <v-col cols="12">
      <v-container class="text-center">
        <h2>Podsumowanie</h2>
      </v-container>
    </v-col>
    <v-col cols="4">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="warning" class="mr-12" size="64">
            mdi-arrow-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption warning--text text-uppercase">
              Łącznie - Wszystkie Platformy
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalAllPlatforms"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
    
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="error" class="mr-12" size="64">
            mdi-arrow-bottom-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption error--text text-uppercase">
              Łącznie - Wypłaty
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalPayouts"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
    
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card class="mx-auto" color="dark lighten-4" max-width="600">
        <v-card-title>
          <v-icon color="error" class="mr-12" size="64">
            mdi-arrow-bottom-right-thick
          </v-icon>
          <v-row align="start">
            <div class="text-caption error--text text-uppercase">
              Łączny ZUS
            </div>
            <div>
              <span class="text-h3 font-weight-black" v-text="totalZus"></span>
              <strong>PLN</strong>
            </div>
          </v-row>
    
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-col>    
  </v-row>
  </v-card>
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
dayjs.extend(weekOfYear)
export default {
  name: "Dashboard",  
  data: () => ({
    totalBolt: 0,
    totalUber: 0,
    totalUberEats: 0,
    totalFreeNow: 0,
    totalAllPlatforms: 0,
    totalPayouts: 0,
    totalAdministrationCost: 0,
    totalSettlementCost: 0,
    totalZus: 0,
    profit: 0,

    departaments: [],
    departament: '',  
    weekNo: dayjs().format('YYYY') + '-W' + (dayjs().week() - 1),     
  }),
  async mounted(){
    await this.getDepartamentSelect()
    await this.loadDashboard()
  },
  
  methods:{
    async getDepartamentSelect() {
      await this.toggleLoading(true);
      try {
        let dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/departaments/list-departaments-for-user/${localStorage.getItem('userid')}?itemsPerPage=100&sort=departamentName&direction=false`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dep.data.data.items.forEach((item, i) => {
          if (i == 0) {
            this.departament = item.id
          }
        })
        this.departaments = dep.data.data.items;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },     
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },    
    async loadDashboard(){
      await this.toggleLoading(true);
      try {
        let dep = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/settlements/get-dashboard-for-admin/${this.weekNo}/${this.departament}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.totalBolt = dep.data.data.totalBolt
        this.totalUber = dep.data.data.totalUber
        this.totalUberEats = dep.data.data.totalUberEats
        this.totalFreeNow = dep.data.data.totalFreeNow
        this.totalAllPlatforms = dep.data.data.totalAllPlatforms
        this.totalPayouts = dep.data.data.totalPayouts
        this.totalAdministrationCost = dep.data.data.totalAdministrationCost
        this.totalSettlementCost = dep.data.data.totalSettlementCost
        this.totalZus = dep.data.data.totalZus
        this.profit = dep.data.data.profit        
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);      
    }
  }
}
</script>
