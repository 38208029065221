<template>
  <v-card>
    <v-dialog v-model="dialogHistoryCreate" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Dodawanie Histori DKV</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Kwota" v-model="price" required placeholder="Kwota"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select label="Rodzaj" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="balanceType" :items="balanceTypes"
                  item-text="text" item-value="id"></v-select>
              </v-col>              
                <v-col cols="12">
                  <v-text-field v-model="dkvWeek" label="Tydzień Rozliczeniowy" type="week"></v-text-field>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogHistoryCreate = false">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="createHistory()">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :eager="true" v-model="dialogHistory" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogHistory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Historia DKV</v-toolbar-title>
          <v-spacer></v-spacer>    
          <v-btn class="primary" @click="dialogHistoryCreate = true">Dodaj</v-btn>
        </v-toolbar>        
    <v-data-table :headers="headersHistory" :items="dkvHistory" :options.sync="optionsHistory"
      :server-items-length="totalRecordsHistory" :loading="loadingHistory" class="elevation-1">
    </v-data-table>
    </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action }} DKV</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Numer Karty" v-model="dkvNo" required placeholder="Numer Karty"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Limit Karty" v-model="cardLimit" :rules="[(v) => !!v || 'Uzupełnij Pole']" required
                  placeholder="Limit Karty"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select label="Kierowca" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="driver"
                  :items="drivers" item-text="driverName" item-value="id"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="createDkv()">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      Karty DKV
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Szukaj"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="primary" @click="showDialog()">Dodaj</v-btn>
    </v-card-title>
    <v-data-table :search="search" :headers="headers" :items="dkv" :options.sync="options" :server-items-length="totalRecords"
      :loading="loading" class="elevation-1">
      <template v-slot:item.actions="{ item }">
        <v-btn class="warning" @click="getDkvById(item.id)">
          <v-icon small class="pt-1">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.history="{ item }">
        <v-btn class="primary" @click="showCardHistory(item.id)">
          <v-icon small class="pt-1">mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>   
</template>

<script>
import axios from 'axios'
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
  name: "Dkv",
  data: () => ({
        search: '',
        headers: [
          {
            text: 'Numer Karty',
            align: 'start',          
            value: 'dkvNo',
          },
          { text: 'Przypisana Do', value: 'driver', sortable: false },
          { text: "", value: "actions", sortable: false },
          { text: "", value: "history", sortable: false },
        ],    
        options: {},
        totalRecords: 0,
        loading: false,        
        dkv: [],
        // 
        dialog:false,
        dkvNo:'',
        cardLimit:'',
        drivers:[],
        driver:'',
        currentDkvId:null,
        action:'Dodawanie',
        // 
        dialogHistory:false,        
        headersHistory:[
          {
            text: 'Data',
            align: 'start',
            value: 'created_at',
          }, 
          {
            text: 'Kwota',
            align: 'start',
            value: 'price',
          },
        {
          text: 'Rodzaj',
          align: 'start',
          value: 'type',
        },  
          {
            text: 'Kierowca',
            align: 'start',
            value: 'driverName',
            sortable:false
          }
        ],
        dkvHistory:[],
        optionsHistory:{},
        totalRecordsHistory:0,
        loadingHistory:false,
        balanceType:'', 
        balanceTypes: [{
          id: 'positive',
          text: 'Doładowanie'
        },
        {
          id: 'negative',
          text: 'Wydatek'
        }],
        dialogHistoryCreate:false,
        price:'',
        dkvWeek:''
  }),
  watch: {
    options: {
      async handler() {
        await this.loadDkvs()
      },
      deep: true,
    },
    optionsHistory: {
      async handler() {
        await this.getCardHistory()
      },
      deep: true,
    },    
    async search() {
      await this.loadDkvs()
    },       
  },  
  methods:{
    async showDialog(){
      await this.getDriversSelect()
      this.dialog = true
      this.cardLimit = '';
      this.dkvNo = '';
      this.driver = null
      this.action = 'Dodawanie'
    },
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    }, 
    async showCardHistory(id){
      this.dialogHistory = true;
      this.currentDkvId = id
      await this.getCardHistory()
    },
    async getCardHistory(){
      this.loadingHistory = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.optionsHistory
        const dkvs = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/dkv/list-dkv-history-for-card/${this.currentDkvId}?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dkvs.data.data.items.forEach((item) => {
          item.type = item.type =='positive'?'Doładowanie':'Wydatek'
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.driverName = item.driver.firstName + ' ' + item.driver.lastName
          item.price = (item.price / 100).toLocaleString("pl-PL", { style: "currency", currency: "PLN" });         
        })
        this.dkvHistory = dkvs.data.data.items
        this.itemsPerPage = dkvs.data.data.meta.itemsPerPage
        this.page = dkvs.data.data.meta.currentPage
        this.totalRecordsHistory = dkvs.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loadingHistory = false    
    },
    async createHistory(){                       
      await this.toggleLoading(true);
      try {
        if (!this.dkvWeek.trim().length) {
          throw ({ response: { statusText: 'Brak Wybranego Tygodnia!' } })
        }
        if (!this.balanceType.trim().length) {
          throw ({ response: { statusText: 'Brak Wybranego Typu Rekordu!' } })
        }
        if (!this.price.trim().length) {
          throw ({ response: { statusText: 'Podaj Kwotę' } })
        }          
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/dkv/create-dkv-history",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            card: this.currentDkvId,
            price: this.price,
            type: this.balanceType,
            weekNo: this.dkvWeek            
          },
        });
        this.price = ""; 
        this.dkvWeek = "" 
        this.dialogHistoryCreate = false      
        this.balanceType = null                
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.getCardHistory(this.currentDkvId)
    },
    async getDkvById(item) {
      await this.getDriversSelect()
      await this.toggleLoading(true);
      try {
        let dkv = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/dkv/details/" + item,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dialog = true;
        this.action = "Edycja";
        this.currentDkvId = item;
        this.cardLimit = dkv.data.data.cardLimit;
        this.dkvNo = dkv.data.data.dkvNo;
        this.driver = dkv.data.data.driver;
      } catch (e) {
        console.log(e);
        this.dialog = false;
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    },     
    async getDriversSelect() {
      await this.toggleLoading(true);
      try {
        let drivers = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/drivers?itemsPerPage=10000&sort=lastName&direction="+false,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        drivers.data.data.items.reverse()
        drivers.data.data.items.forEach((item) => {
          item.driverName = item.firstName + ' ' + item.lastName
        })
        this.drivers = drivers.data.data.items
      } catch (e) {
        console.log(e);
        this.dialog = false;
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
    }, 
    async createDkv() {
      await this.toggleLoading(true);
      let method = "";
      let url = "";
      if (this.currentDkvId) {
        method = "patch";
        url = process.env.VUE_APP_ROOT_API + "/dkv/" + this.currentDkvId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/dkv";
      }
      try {
        if (!this.cardLimit.trim().length) {
          throw ({ response: { statusText: 'Podaj Limit Karty' } })
        }
        if (!this.dkvNo.trim().length) {
          throw ({ response: { statusText: 'Podaj Numer Karty' } })
        }         
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            dkvNo: this.dkvNo,
            cardLimit: this.cardLimit,
            driver: this.driver || null,
          },
        });
        this.dkvNo = "";
        this.cardLimit = "";
        this.driver = null
        this.dialog = false
        this.currentDkvId = null;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      await this.toggleLoading(false);
      await this.loadDkvs()
    },         
    async loadDkvs() {
      this.loading = true
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        const dkvs = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/dkv/list-dkv?sort=${sortBy.length ? sortBy : 'dkvNo'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&s=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        dkvs.data.data.items.forEach((item)=>{
          item.driver = item.driver.firstName + ' '+ item.driver.lastName
        })
        this.dkv = dkvs.data.data.items
        this.itemsPerPage = dkvs.data.data.meta.itemsPerPage
        this.page = dkvs.data.data.meta.currentPage
        this.totalRecords = dkvs.data.data.meta.totalItems
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText + ' ' + (e.response.data ? e.response.data.message.toString() : ''), "error");
      }
      this.loading = false
    },         
  }
}
</script>
